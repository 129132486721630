@fontFamily: CrimsonText-Italic;

.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  margin-top: 50px;
  padding: 30px 24px;

  > .h2 {
    text-align: center;
    // font-weight: bold;
    font-size: 42px;
    font-family: @fontFamily;
    color: #f9f9f9 !important;
    // padding: 0 160px;
    line-height: 45px;
  }

  .container {
    display: flex;
    margin-top: 50px;

    > .imgItem {
      width: 50%;
      margin-right: 0;
      object-fit: contain;
      height: auto;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    padding: 0;

    > .h2 {
    }

    .container {
      display: block;

      > .imgItem {
        display: inline-block;
        margin-right: 0;
        width: 100%;

        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}

